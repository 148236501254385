import React from "react";
import "./diamonhill.scss";
import logodiamonhill from "../../../asset/utama/diamonhill-logo.webp";
import Cardrumah from "../../../component/cardrumah/cardrumah";
import diamonhill from "./diamonhill.json";
import tanzanite from "../../../asset/diamonhill/tanzanite/tanzanite";
import selenite from "../../../asset/diamonhill/selenite/selenite";
const imageMap = {
  tanzanite,
  selenite,

  // Add other image arrays here if you have more sets
};

const Diamonhill = () => {
  return (
    <div className="diamonhill">
      <div className="container-diamonhill">
        <div className="header-diamonhill">
          <div className="logo">
            <img src={logodiamonhill} alt="diamonhill" />
          </div>
        </div>
        <div className="cardhome-container">
          {diamonhill.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Diamonhill;
