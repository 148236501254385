import Selenite1 from "./selenite (1).webp";
import Selenite2 from "./selenite (2).webp";
import Selenite3 from "./selenite (3).webp";
import Selenite4 from "./selenite (4).webp";
import Selenite5 from "./selenite (5).webp";
import Selenite6 from "./selenite (6).webp";
import Selenite7 from "./selenite (7).webp";
import Selenite8 from "./selenite (8).webp";
import Selenite9 from "./selenite (9).webp";

const selenite = [
  Selenite1,
  Selenite2,
  Selenite3,
  Selenite4,
  Selenite5,
  Selenite6,
  Selenite7,
  Selenite8,
  Selenite9,
];

export default selenite;
