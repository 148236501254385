import React from "react";
import "./new1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Mapleimages from "../maple.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const new1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const importAll = (r) => {
    return r.keys().map(r);
  };

  const oliveImages = importAll(
    require.context("../../../asset/Olive", false, /\.(png|jpe?g|svg)$/)
  );
  const hazelImages = importAll(
    require.context("../../../asset/Hazel", false, /\.(png|jpe?g|svg)$/)
  );

  const olivewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281252221362&text=Halo%20Windy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Olive)%20https://www.cls-citralandsurabaya.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const hazelwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281252221362&text=Halo%20Windy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Hazel)%20https://www.cls-citralandsurabaya.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const maplewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281252221362&text=Halo%20Windy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Maple)%20https://www.cls-citralandsurabaya.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-card">
      <div className="kartu">
        <Slider {...settings}>
          {oliveImages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Astha ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah">Olive</div>
            <span>Dempsey Hill</span>
          </div>
          <div className="cicilan">
            <div className="angka">10</div>
            <div className="ket">Juta/Bulan</div>
          </div>
        </div>
        <hr color="black" />
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 105m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 149m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={olivewa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {hazelImages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Astha ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah">Hazel</div>
            <span>Dempsey Hill</span>
          </div>
          <div className="cicilan">
            <div className="angka">14</div>
            <div className="ket">Juta/Bulan</div>
          </div>
        </div>
        <hr color="black" />
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 135m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 191m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3+1</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3+1</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={hazelwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Mapleimages.map((image, index) => (
            <div>
              <img
                className="kartugambar1"
                key={index}
                src={image}
                alt="maple"
              />
            </div>
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah">Maple</div>
            <span>Dempsey Hill</span>
          </div>
          <div className="cicilan">
            <div className="angka">19</div>
            <div className="ket">Juta/Bulan</div>
          </div>
        </div>
        <hr color="black" />
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 170m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 255m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 4+1</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3+1</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={maplewa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default new1;
