import React from "react";
import "./promonewlaunch.scss";
import gambar from "./promo citraland selenite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const promonewlaunch = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281252221362&text=Halo%20Windy,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20(promo-selenite)%20https://marketing-citralandsurabaya.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="a">
      <div id="promo-selenite" className="container-promonew1">
        <div className="containercontent">
          <div className="point-promonew1">
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free DP 5%</span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Biaya KPR*
              </span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free BPHTB*</span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Biaya AJB*
              </span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Free AC*</span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Full Smarthome System
              </span>
            </div>
            <div className="promonew1-poin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Upgrade Daya*
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonNew3" onClick={handleConsultationClick}>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
        <div className="containergambar">
          <img className="promonew1-gambar" src={gambar} />
        </div>
      </div>
    </div>
  );
};

export default promonewlaunch;
